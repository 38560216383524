<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">科室绩效应用方案</h2>
                <div class="hosrow">
                    <div>
                        <el-select v-model="searcdepartmentId" placeholder="请选择科室" clearable @change="seardepId(searcdepartmentId)">
                            <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-button type="primary" @click="depaddKeshi">配置指标</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="科室名称" align="center">
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="90">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editdepp(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <!-- <el-button size="mini" type="primary" @click="showdepp(scope.row)"><i class="el-icon-tickets"></i></el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击新增人员信息弹出框 -->
        <el-dialog :title="diaTit" :visible.sync="keshidialogVisible" width="305px" :before-close="keshidialogVisibleClose">

            <el-form :model="formModelDep" ref="formDepRef" :rules="formDepRules">

                <el-form-item label="科室：" prop="departmentId" class="keshiClas">
                    <el-select filterable v-model="formModelDep.departmentId" :disabled='disabled' placeholder="请选择科室" clearable @change="depChange(formModelDep.departmentId)">
                        <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <div class="btnCen">
                    <el-button type="primary" :loading="nextDepClickKing" @click="keshiBtn">下一步</el-button>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog :title="diaTit" :visible.sync="AdddialogVisible" width="85%" top="1vh" :before-close="AdddialogVisibleClose" :close-on-click-modal="false">

            <el-form :model="formModel" ref="formRef" :rules="formRules" label-width="100px" class="formClaRul">

                <div class="centerClas">
                    <el-form-item label="科室：" prop="departmentId" style="margin-left:38px">
                        <el-select v-model="formModel.departmentId" filterable :disabled='disabled' placeholder="请选择科室" clearable @change="depChange(formModel.departmentId)">
                            <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <!-- 步骤 -->
                <el-steps :active="active" finish-status="success" align-center v-if="stepShow">
                    <el-step v-for="(item, index) of stepParams" :key="index" :title="item.title" @click.native="tabSwitchBtn(item)" />
                </el-steps>

                <div class="stepClass">

                    <!-- 基本指标 -->
                    <div v-if="baShow">
                        <div v-if="checShow">
                            <el-checkbox v-model="baschecked" @change="workselect(baschecked)" /> 全选/反选
                        </div>
                        <el-form-item prop="basValue">
                            <indTree ref="bastree" :targetName='targetName' :checkKeys='checkKeys' :data='basdata' @basnodeClick='basnodeClick' />
                        </el-form-item>

                    </div>

                    <!-- 工作量指标-医疗服务项目 -->
                    <div v-if="workShow">
                        <el-form-item prop="workValue" class="tabClas">
                            <el-table ref="multipleTable" max-height="400" center border :data="basdata" tooltip-effect="dark" style="width: 91%;margin:0 auto;margin-top:20px;" @selection-change="selecoseAll">
                                <el-table-column type="selection" width="55" align="center">
                                </el-table-column>
                                <el-table-column prop="name" label="指标名称" align="center">
                                </el-table-column>
                                <el-table-column prop="intension" label="项目内涵" align="center">
                                    <template slot-scope="scope">
                                        <el-tooltip class="item" effect="dark" placement="top">
                                            <div class="heiClas">{{ scope.row.intension }}</div>
                                            <div style="width: 500px" slot="content">
                                                <div>{{ scope.row.intension }}</div>
                                            </div>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="explain" label="指标说明" align="center">
                                    <template slot-scope="scope">
                                        <el-tooltip class="item" effect="dark" placement="top">
                                            <div class="heiClas">{{ scope.row.explain }}</div>
                                            <div style="width: 500px" slot="content">
                                                <div>{{ scope.row.explain }}</div>
                                            </div>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </div>
                    <!-- 工作量指标-病例病种 -->
                    <div v-if="workTwoShow">
                        <el-form-item prop="workTwoValue" class="tabClas">
                            <el-table ref="multipleTable" max-height="400" center border :data="basdata" tooltip-effect="dark" style="width: 91%;margin:0 auto;margin-top:20px;" @selection-change="woTtabselecoseAll">
                                <el-table-column type="selection" width="55" align="center">
                                </el-table-column>
                                <el-table-column prop="name" label="指标名称" align="center">
                                </el-table-column>
                                <el-table-column prop="intension" label="项目内涵" align="center">
                                    <!-- <template slot-scope="scope">
                                        <el-tooltip class="item" effect="dark" placement="top">
                                            <div class="heiClas">{{ scope.row.intension }}</div>
                                            <div style="width: 500px" slot="content">
                                                <div>{{ scope.row.intension }}</div>
                                            </div>
                                        </el-tooltip>
                                    </template> -->
                                </el-table-column>
                                <el-table-column prop="explain" label="指标说明" align="center">
                                    <!-- <template slot-scope="scope">
                                        <el-tooltip class="item" effect="dark" placement="top">
                                            <div class="heiClas">{{ scope.row.explain }}</div>
                                            <div style="width: 500px" slot="content">
                                                <div>{{ scope.row.explain }}</div>
                                            </div>
                                        </el-tooltip>
                                    </template> -->
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </div>

                    <!-- 工作量指标-业务量 -->
                    <div v-if="busvShow">
                        <div v-if="checShow">
                            <el-checkbox v-model="buschecked" @change="workselect(buschecked)" /> 全选/反选
                        </div>

                        <el-form-item prop="busvValue">
                            <indTree ref="bastree" :checkKeys='checkKeys' :data='basdata' @basnodeClick='busvchooseAll' />
                        </el-form-item>

                    </div>

                    <!-- 专项奖励指标 -->
                    <div v-if="speShow">
                        <div v-if="checShow">
                            <el-checkbox v-model="spechecked" @change="workselect(spechecked)" /> 全选/反选
                        </div>

                        <el-form-item prop="speValue">
                            <indTree ref="bastree" :checkKeys='checkKeys' :data='basdata' @basnodeClick='specchooseAll' />
                        </el-form-item>
                    </div>

                    <!-- 单项补助及奖惩指标 -->
                    <div v-if="punShow">
                        <div v-if="checShow">
                            <el-checkbox v-model="puninchecked" @change="workselect(puninchecked)" /> 全选/反选
                        </div>

                        <el-form-item prop="punValue">
                            <indTree ref="bastree" :checkKeys='checkKeys' :data='basdata' @basnodeClick='puninchooseAll' />
                        </el-form-item>
                    </div>

                    <!-- 个人考勤指标 -->
                    <div v-if="indShow">
                        <div v-if="checShow">
                            <el-checkbox v-model="indechecked" @change="workselect(indechecked)" /> 全选/反选
                        </div>

                        <el-form-item prop="indValue">
                            <indTree ref="bastree" :checkKeys='checkKeys' :data='basdata' @basnodeClick='indechooseAll' />
                        </el-form-item>
                    </div>

                    <!-- 附加职责 -->
                    <div v-if="addShow">
                        <div v-if="checShow">
                            <el-checkbox v-model="addreschecked" @change="workselect(addreschecked)" /> 全选/反选
                        </div>

                        <el-form-item prop="addValue">
                            <indTree ref="bastree" :checkKeys='checkKeys' :data='basdata' @basnodeClick='addchooseAll' />
                        </el-form-item>

                    </div>
                </div>

                <div class="btnBig">
                    <div class="btnCen">
                        <el-button type="primary" @click="preBtn">上一步</el-button>
                    </div>

                    <div class="btnCen" v-if="stepBtn == '下一步'">
                        <el-button v-if="nextDepClick" type="primary" :loading="nextDepClickKing" @click="next">下一步</el-button>
                        <el-button v-else type="primary" :loading="nextDepClickKing"></el-button>
                    </div>

                    <div class="btnCen" v-if="stepBtn == '提交'">
                        <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="addDepp">提交</el-button>
                         <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                    </div>

                    <div class="btnCen" v-if="updatShow">
                        <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="perupad">修改</el-button>
                         <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                    </div>

                    <div class="btnCen">
                        <el-button type="primary" @click="AdddialogVisibleClose">关闭</el-button>
                    </div>
                </div>

            </el-form>

        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
import indTree from "../../Department/AddInMan/indTree.vue";
import addindTree from "../../Department/AddInMan/addindTree.vue";
export default {
    data() {
        var depValda = async (rule, value, callback) => {
            if (!value && String(value) !== "0") {
                return callback(new Error("请选择科室"));
            } else {
                if (this.ediDepaId == this.formModel.departmentId) {
                    callback();
                } else {
                    let data = _qs.stringify({
                        departmentId: value,
                    });
                    let { data: res } = await this.$axios.perjudge(data);
                    // console.log(res);
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        callback();
                    } else {
                        return callback(new Error(res.msg));
                    }
                }

                if (this.addVal) {
                    let data = _qs.stringify({
                        departmentId: value,
                    });
                    let { data: res } = await this.$axios.perjudge(data);
                    // console.log(res);
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        callback();
                    } else {
                        return callback(new Error(res.msg));
                    }
                } else {
                    callback();
                }
            }
        };
        var basValda = async (rule, value, callback) => {
            if (this.addVal) {
                if (value.length <= 0) {
                    return callback(new Error("请选择基本指标"));
                }
            } else {
                callback();
            }
        };
        var workValda = async (rule, value, callback) => {
            if (this.addVal) {
                if (value.length <= 0) {
                    return callback(new Error("请选择工作量指标-医疗服务项目"));
                }
            } else {
                callback();
            }
        };
        var busvValda = async (rule, value, callback) => {
            if (this.addVal) {
                if (value.length <= 0) {
                    return callback(new Error("请选择工作量指标-业务量"));
                }
            } else {
                callback();
            }
        };
        var workTwoValda = async (rule, value, callback) => {
            if (this.addVal) {
                if (value.length <= 0) {
                    return callback(new Error("请选择工作量指标-病历病种"));
                }
            } else {
                callback();
            }
        };
        var speValda = async (rule, value, callback) => {
            if (this.addVal) {
                if (value.length <= 0) {
                    return callback(new Error("请选择专项奖励指标"));
                }
            } else {
                callback();
            }
        };
        var punValda = async (rule, value, callback) => {
            if (this.addVal) {
                if (value.length <= 0) {
                    return callback(new Error("请选择单项补助及奖惩指标"));
                }
            } else {
                callback();
            }
        };
        var indValda = async (rule, value, callback) => {
            if (this.addVal) {
                if (value.length <= 0) {
                    return callback(new Error("请选择个人考勤指标"));
                }
            } else {
                callback();
            }
        };
        var addValda = async (rule, value, callback) => {
            if (this.addVal) {
                if (value.length <= 0) {
                    return callback(new Error("请选择附加职责"));
                }
            } else {
                callback();
            }
        };
        return {
            targetName: "基本指标",
            updatShow: false,
            tit: "附加职责",
            checShow: true,
            checkKeys: [],
            baschecked: false,
            buschecked: false,
            spechecked: false,
            stepBtn: "下一步",
            disabled: false,
            stepShow: true,
            baShow: false,
            workShow: false,
            workTwoShow: false,
            busvShow: false,
            speShow: false,
            punShow: false,
            indShow: false,
            addShow: false,

            indechecked: false,
            puninchecked: false,
            addreschecked: false,
            basdata: [],
            docadddata: [],
            nuradddata: [],
            stepParams: [
                { title: "基本指标", index: 0, id: "0", workType: "" },
                {
                    title: "工作量指标-医疗服务项目",
                    index: 1,
                    id: "1",
                    workType: "1",
                },
                {
                    title: "工作量指标-病历病种",
                    index: 2,
                    id: "1",
                    workType: "0",
                },
                {
                    title: "工作量指标-业务量",
                    index: 3,
                    id: "1",
                    workType: "2",
                },
                { title: "专项奖励指标", index: 4, id: "2", workType: "" },
                {
                    title: "单项补助及奖惩指标",
                    index: 5,
                    id: "3",
                    workType: "",
                },
                { title: "个人考勤指标", index: 6, id: "4", workType: "" },
                { title: "附加职责", index: 7, id: "5", workType: "" },
            ],
            searcdepartmentId: "",
            addVal: true,
            addDepClick: true,
            addDepClickKing: false,
            nextDepClickKing: false,
            nextDepClick: true,
            formModelDep: {
                departmentId: "",
            },
            formModel: {
                departmentId: "",
                target: [],
                basValue: [], //基本指标
                workValue: [],
                busvValue: [],
                workTwoValue: [],
                speValue: [],
                punValue: [],
                indValue: [],
                addValue: [],
            },
            depoptions: [],
            formDepRules: {
                departmentId: [
                    { required: true, validator: depValda, trigger: "blur" },
                ],
            },
            formRules: {
                addValue: [
                    { required: true, validator: addValda, trigger: "blur" },
                ],
                indValue: [
                    { required: true, validator: indValda, trigger: "blur" },
                ],
                punValue: [
                    { required: true, validator: punValda, trigger: "blur" },
                ],
                speValue: [
                    { required: true, validator: speValda, trigger: "blur" },
                ],
                workValue: [
                    { required: true, validator: workValda, trigger: "blur" },
                ],
                busvValue: [
                    { required: true, validator: busvValda, trigger: "blur" },
                ],
                workTwoValue: [
                    {
                        required: true,
                        validator: workTwoValda,
                        trigger: "blur",
                    },
                ],
                basValue: [
                    { required: true, validator: basValda, trigger: "blur" },
                ], //基本指标
                target: [
                    {
                        required: true,
                        message: "请选择指标",
                        trigger: "change",
                    },
                ],
                departmentId: [
                    { required: true, validator: depValda, trigger: "blur" },
                ],
            },
            value: true,
            novalue: false,
            diaBtn: "配置",
            diaTit: "配置指标",
            // 点击添加科室弹出框
            AdddialogVisible: false,
            AddDateModel: {},
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 表格数据
            tableData: [],
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            active: 0,
            ediDepaId: "",
            editShow: false,
            nurVal: [],
            docVal: [],
            basicLibraryList: "",
            keshidialogVisible: false,
        };
    },
    components: {
        indTree,
        addindTree,
    },
    created() {
        this.rewardList();
        this.findDepartment();
    },
    methods: {
        // 点击stemp文字
        tabSwitchBtn(val) {
            // if (this.disabled) {
            if (this.basdata.length <= 0) {
                this.valRefChan();
            } else {
                this.$refs.formRef.validate(async (valid) => {
                    if (valid) {
                        this.active = val.index;
                        this.stepChange();
                        if (this.active == 7) {
                            this.stepBtn = "关闭";
                        } else {
                            this.stepBtn = "下一步";
                        }
                        let array = [];
                        array = [
                            ...this.formModel.addValue,
                            ...this.formModel.indValue,
                            ...this.formModel.punValue,
                            ...this.formModel.speValue,
                            ...this.formModel.workValue,
                            ...this.formModel.workTwoValue,
                            ...this.formModel.basValue,
                            ...this.formModel.busvValue,
                        ];
                        let checArr = [];
                        for (let i = 0; i < array.length; i++) {
                            checArr.push(array[i].id);
                        }
                        this.checkKeys = checArr;
                    }
                });
            }

            // } else {
            //     this.$message({
            //         message: "请按照步骤进行选择",
            //         type: "error",
            //     });
            // }
        },
        // 上一步
        preBtn() {
            if (!this.addVal) {
                // 查看详情
                if (this.active-- <= 0) this.active = 0;
                this.stepChange();
                if (this.active == 7) {
                    this.stepBtn = "关闭";
                } else {
                    this.stepBtn = "下一步";
                }
            } else {
                // 修改和配置
                if (this.active-- <= 0) this.active = 0;
                this.stepChange();
                this.stepBtn = "下一步";
                let array = [];
                array = [
                    ...this.formModel.addValue,
                    ...this.formModel.indValue,
                    ...this.formModel.punValue,
                    ...this.formModel.speValue,
                    ...this.formModel.workValue,
                    ...this.formModel.workTwoValue,
                    ...this.formModel.basValue,
                    ...this.formModel.busvValue,
                ];
                let checArr = [];
                for (let i = 0; i < array.length; i++) {
                    checArr.push(array[i].id);
                }
                this.checkKeys = checArr;
            }
        },
        // 下一步
        next() {
            if (!this.addVal) {
                if (this.active++ > 7) this.active = 1;
                this.stepChange();
                if (this.active == 7) {
                    this.stepBtn = "关闭";
                } else {
                    this.stepBtn = "下一步";
                }
            } else {
                if (this.basdata.length <= 0) {
                    this.valRefChan();
                } else {
                    this.$refs.formRef.validate(async (valid) => {
                        if (valid) {
                            this.valRefChan();
                        }
                    });
                }
            }
        },
        depChange(val) {
            this.formModelDep.departmentId = val;
            this.formModel.departmentId = val;
        },
        workselect(val) {
            // console.log(val);
            this.$refs.bastree.baschooseAll(val);
        },
        basnodeClick(val) {
            // console.log(val);
            // console.log(this.basdata);
            let obj = [];
            for (let i = 0; i < val.length; i++) {
                // console.log(val[i]);
                obj.push({
                    id: val[i],
                });
            }
            this.formModel.basValue = obj;
            // console.log(this.formModel.basValue);
            let sum = 0;
            for (let j = 0; j < this.basdata.length; j++) {
                sum += this.basdata[j].indicatorThirdLibraryList.length;
            }
            // console.log(sum);
            if (sum == val.length) {
                this.baschecked = true;
            } else {
                this.baschecked = false;
            }
        },
        workchooseAll(val) {
            console.log();
            let obj = [];
            for (let i = 0; i < val.length; i++) {
                // console.log(val[i]);
                obj.push({
                    id: val[i],
                });
            }
            this.formModel.workValue = obj;
        },
        busvchooseAll(val) {
            // console.log(val);
            let obj = [];
            for (let i = 0; i < val.length; i++) {
                // console.log(val[i]);
                obj.push({
                    id: val[i],
                });
            }

            this.formModel.busvValue = obj;
            // console.log(this.formModel.busvValue);
            let sum = 0;
            for (let j = 0; j < this.basdata.length; j++) {
                sum += this.basdata[j].indicatorThirdLibraryList.length;
            }
            if (sum == val.length) {
                this.buschecked = true;
            } else {
                this.buschecked = false;
            }
        },
        selecoseAll(val) {
            // console.log(val);
            this.formModel.workValue = val;
        },
        woTtabselecoseAll(val) {
            // console.log(val);
            this.formModel.workTwoValue = val;
        },
        workTwochooseAll(val) {
            let obj = [];
            for (let i = 0; i < val.length; i++) {
                // console.log(val[i]);
                obj.push({
                    id: val[i],
                });
            }
            this.formModel.workTwoValue = obj;
        },
        specchooseAll(val) {
            // console.log(val);
            let obj = [];
            for (let i = 0; i < val.length; i++) {
                // console.log(val[i]);
                obj.push({
                    id: val[i],
                });
            }
            this.formModel.speValue = obj;
            // console.log(this.formModel.speValue);
            let sum = 0;
            for (let j = 0; j < this.basdata.length; j++) {
                sum += this.basdata[j].indicatorThirdLibraryList.length;
            }
            if (sum == val.length) {
                this.spechecked = true;
            } else {
                this.spechecked = false;
            }
        },
        indechooseAll(val) {
            let obj = [];
            for (let i = 0; i < val.length; i++) {
                // console.log(val[i]);
                obj.push({
                    id: val[i],
                });
            }
            this.formModel.indValue = obj;

            let sum = 0;
            for (let j = 0; j < this.basdata.length; j++) {
                sum += this.basdata[j].indicatorThirdLibraryList.length;
            }
            if (sum == val.length) {
                this.indechecked = true;
            } else {
                this.indechecked = false;
            }
        },
        addchooseAll(val) {
            // console.log(val);
            let obj = [];
            for (let i = 0; i < val.length; i++) {
                // console.log(val[i]);
                obj.push({
                    id: val[i],
                });
            }
            // console.log(obj);
            this.formModel.addValue = obj;
            // console.log(this.formModel.addValue);

            let sum = 0;
            for (let j = 0; j < this.basdata.length; j++) {
                sum += this.basdata[j].indicatorThirdLibraryList.length;
            }
            if (sum == val.length) {
                this.addreschecked = true;
            } else {
                this.addreschecked = false;
            }
        },
        addreschooseAll(val) {
            if (val) {
                if (this.nurVal.length > 0) {
                    this.$refs.nurbastree.baschooseAll(false);
                }
            }

            // console.log(val);
            // let newVal = val.slice(1);
            let newVal = val;
            let obj = [];
            for (let i = 0; i < newVal.length; i++) {
                // console.log(newVal[i]);
                obj.push({
                    id: newVal[i],
                });
            }
            // this.formModel.addValue = obj;
            this.docVal = obj;
        },
        nuraddreschooseAll(val) {
            if (val) {
                if (this.docVal.length > 0) {
                    this.$refs.docbastree.baschooseAll(false);
                }
            }
            // console.log(val);
            // let newVal = val.slice(1);
            let newVal = val;
            let obj = [];
            for (let i = 0; i < newVal.length; i++) {
                // console.log(newVal[i]);
                obj.push({
                    id: newVal[i],
                });
            }
            this.nurVal = obj;
            // this.formModel.addValue = obj;
        },
        puninchooseAll(val) {
            let obj = [];
            for (let i = 0; i < val.length; i++) {
                // console.log(val[i]);
                obj.push({
                    id: val[i],
                });
            }
            this.formModel.punValue = obj;

            let sum = 0;
            for (let j = 0; j < this.basdata.length; j++) {
                sum += this.basdata[j].indicatorThirdLibraryList.length;
            }
            if (sum == val.length) {
                this.puninchecked = true;
            } else {
                this.puninchecked = false;
            }
        },
        valRefChan() {
            if (this.active == 7) {
                this.nextDepClick = false;
                this.nextDepClickKing = true;
            } else {
                this.nextDepClick = true;
                this.nextDepClickKing = false;
            }

            this.stepShow = true;
            if (this.stepShow) {
                if (this.active++ > 7) this.active = 0;
                this.stepChange();

                if (this.editShow) {
                    this.disabled = true;
                    // this.editfindDetail();
                } else {
                    this.disabled = false;
                }
            } else {
                this.active = 0;
                this.disabled = false;
            }
        },
        stepChange() {
            // console.log(this.active);
            for (let i = 0; i < this.stepParams.length; i++) {
                if (this.active == this.stepParams[i].index) {
                    // console.log(this.stepParams[i].title);
                    this.optionsel(this.stepParams[i].title);
                    if (this.stepParams[i].title == "基本指标") {
                        this.baShow = true;
                        this.workShow = false;
                        this.speShow = false;
                        this.punShow = false;
                        this.indShow = false;
                        this.addShow = false;
                        this.workTwoShow = false;
                        this.busvShow = false;
                    } else if (
                        this.stepParams[i].title == "工作量指标-医疗服务项目"
                    ) {
                        this.baShow = false;
                        this.workShow = true;
                        this.speShow = false;
                        this.punShow = false;
                        this.indShow = false;
                        this.addShow = false;
                        this.workTwoShow = false;
                        this.busvShow = false;
                    } else if (
                        this.stepParams[i].title == "工作量指标-病历病种"
                    ) {
                        this.baShow = false;
                        this.workShow = false;
                        this.workTwoShow = true;
                        this.speShow = false;
                        this.punShow = false;
                        this.indShow = false;
                        this.addShow = false;
                        this.busvShow = false;
                    } else if (
                        this.stepParams[i].title == "工作量指标-业务量"
                    ) {
                        this.baShow = false;
                        this.workShow = false;
                        this.workTwoShow = false;
                        this.speShow = false;
                        this.punShow = false;
                        this.indShow = false;
                        this.addShow = false;
                        this.busvShow = true;
                    } else if (this.stepParams[i].title == "专项奖励指标") {
                        this.baShow = false;
                        this.workShow = false;
                        this.speShow = true;
                        this.punShow = false;
                        this.indShow = false;
                        this.addShow = false;
                        this.workTwoShow = false;
                        this.busvShow = false;
                    } else if (
                        this.stepParams[i].title == "单项补助及奖惩指标"
                    ) {
                        this.baShow = false;
                        this.workShow = false;
                        this.speShow = false;
                        this.punShow = true;
                        this.indShow = false;
                        this.addShow = false;
                        this.busvShow = false;
                        this.workTwoShow = false;
                    } else if (this.stepParams[i].title == "个人考勤指标") {
                        this.baShow = false;
                        this.workShow = false;
                        this.speShow = false;
                        this.punShow = false;
                        this.indShow = true;
                        this.addShow = false;
                        this.workTwoShow = false;
                        this.busvShow = false;
                    } else if (this.stepParams[i].title == "附加职责") {
                        // this.docfindBySys();
                        // this.nurfindBySys();
                        this.baShow = false;
                        this.workShow = false;
                        this.speShow = false;
                        this.punShow = false;
                        this.indShow = false;
                        this.addShow = true;
                        this.workTwoShow = false;
                        this.busvShow = false;
                        if (this.editShow) {
                            this.stepBtn = "修改";
                        } else {
                            this.stepBtn = "提交";
                        }
                    }
                }
            }
        },
        async optionsel(val) {
            // console.log(val);
            let dictType, dicworkId;
            if (val == "基本指标") {
                dictType = "0";
                dicworkId = "";
            } else if (val == "工作量指标-医疗服务项目") {
                dictType = "1";
                dicworkId = "1";
            } else if (val == "工作量指标-病历病种") {
                dictType = "1";
                dicworkId = "0";
            } else if (val == "工作量指标-业务量") {
                dictType = "1";
                dicworkId = "2";
            } else if (val == "专项奖励指标") {
                dictType = "2";
                dicworkId = "";
            } else if (val == "单项补助及奖惩指标") {
                dictType = "3";
                dicworkId = "";
            } else if (val == "个人考勤指标") {
                dictType = "4";
                dicworkId = "";
            } else if (val == "附加职责") {
                dictType = "5";
                dicworkId = "";
            }

            let data = {
                indicatorLibraryType: dictType, //指标类型（0基本指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                status: "0",
                deleteFlag: "0",
                workloadIndicatorType: dicworkId, //工作量指标类型（0病例病种赋分   1医疗服务项目  2业务量）  该项参数在指标类型为工作量类型时为必传
            };
            let { data: res } = await this.$axios.perlibDetail(data);
            this.nextDepClick = true;
            this.nextDepClickKing = false;
            // console.log(res);
            if (res.code == 400) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.basdata = [];

                for (let i = 0; i < res.data.length; i++) {
                    // console.log(res.data[i].name);
                    // console.log(val);
                    // console.log(res.data[i].indicatorThirdLibraryList);
                    for (
                        let n = 0;
                        n < res.data[i].indicatorThirdLibraryList.length;
                        n++
                    ) {
                        if (!this.addVal) {
                            res.data[i].indicatorThirdLibraryList[n].disabled =
                                "true";
                        }

                        if (val == "工作量指标-医疗服务项目") {
                            if (res.data[i].name == "医疗服务项目") {
                                this.basdata =
                                    res.data[i].indicatorThirdLibraryList;
                            }
                        } else if (val == "工作量指标-病历病种") {
                            if (res.data[i].name == "病历病种") {
                                this.basdata =
                                    res.data[i].indicatorThirdLibraryList;
                            }
                        } else if (val == "工作量指标-业务量") {
                            if (res.data[i].name == "业务量") {
                                let arr = [];
                                arr.push(res.data[i]);
                                this.basdata = arr;
                            }
                        } else {
                            this.basdata = res.data;
                        }
                    }
                }
                // console.log(this.basdata);
                // 表格的回显
                if (
                    val == "工作量指标-医疗服务项目" ||
                    val == "工作量指标-病历病种"
                ) {
                    setTimeout(() => {
                        for (let i = 0; i < this.checkKeys.length; i++) {
                            this.basdata.forEach((x) => {
                                if (x.id == this.checkKeys[i]) {
                                    this.$refs.multipleTable.toggleRowSelection(
                                        x
                                    );
                                }
                            });
                        }
                    }, 10);
                }
                this.$nextTick(() => {
                    if (val == "基本指标") {
                        //  基本指标
                        let sum = 0;
                        for (let j = 0; j < this.basdata.length; j++) {
                            sum +=
                                this.basdata[j].indicatorThirdLibraryList
                                    .length;
                        }
                        if (this.formModel.basValue.length == sum) {
                            this.baschecked = true;
                        } else {
                            this.baschecked = false;
                        }
                    } else if (val == "工作量指标-业务量") {
                        let sum = 0;
                        for (let j = 0; j < this.basdata.length; j++) {
                            sum +=
                                this.basdata[j].indicatorThirdLibraryList
                                    .length;
                        }
                        if (this.formModel.busvValue.length == sum) {
                            this.buschecked = true;
                        } else {
                            this.buschecked = false;
                        }
                    } else if (val == "专项奖励指标") {
                        let sum = 0;
                        for (let j = 0; j < this.basdata.length; j++) {
                            sum +=
                                this.basdata[j].indicatorThirdLibraryList
                                    .length;
                        }
                        if (this.formModel.speValue.length == sum) {
                            this.spechecked = true;
                        } else {
                            this.spechecked = false;
                        }
                    } else if (val == "单项补助及奖惩指标") {
                        let sum = 0;
                        for (let j = 0; j < this.basdata.length; j++) {
                            sum +=
                                this.basdata[j].indicatorThirdLibraryList
                                    .length;
                        }
                        if (this.formModel.punValue.length == sum) {
                            this.puninchecked = true;
                        } else {
                            this.puninchecked = false;
                        }
                    } else if (val == "个人考勤指标") {
                        let sum = 0;
                        for (let j = 0; j < this.basdata.length; j++) {
                            sum +=
                                this.basdata[j].indicatorThirdLibraryList
                                    .length;
                        }
                        if (this.formModel.indValue.length == sum) {
                            this.indechecked = true;
                        } else {
                            this.indechecked = false;
                        }
                    } else if (val == "附加职责") {
                        let sum = 0;
                        for (let j = 0; j < this.basdata.length; j++) {
                            sum +=
                                this.basdata[j].indicatorThirdLibraryList
                                    .length;
                        }
                        if (this.formModel.addValue.length == sum) {
                            this.addreschecked = true;
                        } else {
                            this.addreschecked = false;
                        }
                    }
                });
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async docfindBySys() {
            let data = {
                dataType: "1", //科室绩效专用数据类型（0岗位职责  1附加职责  2基本指标）
            };

            let { data: res } = await this.$axios.optionselect(data);
            // console.log(res);
            if (res.code == 400) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                this.docadddata.push({
                    id: res.data[0].dictType,
                    name: res.data[0].dictName,
                    children: [],
                });

                let docdata = {
                    dictType: res.data[0].dictType,
                    status: 0,
                    dictName: "",
                };

                let docres = await this.$axios.findBySys(docdata);
                // console.log(docres);
                if (docres.status == 200) {
                    for (let i = 0; i < docres.data.length; i++) {
                        this.docadddata[0].children.push({
                            id: docres.data[i].dictCode,
                            name: docres.data[i].dictLabel,
                        });
                    }
                }
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async nurfindBySys() {
            let data = {
                dataType: "1", //科室绩效专用数据类型（0岗位职责  1附加职责  2基本指标）
            };

            let { data: res } = await this.$axios.optionselect(data);
            // console.log(res);
            if (res.code == 400) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                this.nuradddata.push({
                    id: res.data[1].dictType,
                    name: res.data[1].dictName,
                    children: [],
                });

                let docdata = {
                    dictType: res.data[1].dictType,
                    status: 0,
                    dictName: "",
                };

                let docres = await this.$axios.findBySys(docdata);
                // console.log(docres);
                if (docres.status == 200) {
                    for (let i = 0; i < docres.data.length; i++) {
                        this.nuradddata[0].children.push({
                            id: docres.data[i].dictCode,
                            name: docres.data[i].dictLabel,
                        });
                    }
                }
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        seardepId(val) {
            // console.log(val);
            this.searcdepartmentId = val;
            this.rewardList();
        },
        editdepp(val) {
            // console.log(val);
            this.AdddialogVisible = true;
            this.updatShow = true;
            this.formModel.departmentId = val.departmentId;
            this.ediDepaId = val.departmentId;
            this.addVal = true;
            this.editShow = true;
            this.disabled = true;
            this.stepChange();
            this.findByType();
        },
        async findByType() {
            let data = _qs.stringify({
                departmentId: this.formModel.departmentId, //所属科室id
            });
            let { data: res } = await this.$axios.findByType(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                let basarr = [],
                    addarr = [],
                    indarr = [],
                    punarr = [],
                    workarr = [],
                    workTwoarr = [],
                    busvarr = [],
                    spearr = [];

                // 基本指标
                this.formModel.basValue = [];
                for (let i = 0; i < res.data.basicLibraryList.length; i++) {
                    basarr.push(
                        ...res.data.basicLibraryList[i]
                            .indicatorThirdLibraryList
                    );
                }
                for (let k = 0; k < basarr.length; k++) {
                    this.formModel.basValue.push({
                        id: basarr[k].id,
                    });
                }
                // console.log(this.formModel.basValue);

                // 工作量指标
                for (let i = 0; i < res.data.workloadLibraryList.length; i++) {
                    if (
                        res.data.workloadLibraryList[i].name == "医疗服务项目"
                    ) {
                        workarr.push(
                            ...res.data.workloadLibraryList[i]
                                .indicatorThirdLibraryList
                        );
                    }
                    if (res.data.workloadLibraryList[i].name == "病历病种") {
                        workTwoarr.push(
                            ...res.data.workloadLibraryList[i]
                                .indicatorThirdLibraryList
                        );
                    }
                    if (res.data.workloadLibraryList[i].name == "业务量") {
                        busvarr.push(
                            ...res.data.workloadLibraryList[i]
                                .indicatorThirdLibraryList
                        );
                    }
                }
                for (let n = 0; n < workarr.length; n++) {
                    this.formModel.workValue.push({
                        id: workarr[n].id,
                    });
                }
                for (let n = 0; n < workTwoarr.length; n++) {
                    this.formModel.workTwoValue.push({
                        id: workTwoarr[n].id,
                    });
                }
                for (let n = 0; n < busvarr.length; n++) {
                    this.formModel.busvValue.push({
                        id: busvarr[n].id,
                    });
                }
                // console.log(this.formModel.busvValue);
                // console.log(this.formModel.workTwoValue);
                // 专项奖励指标
                for (
                    let i = 0;
                    i < res.data.specialRewardsLibraryList.length;
                    i++
                ) {
                    spearr.push(
                        ...res.data.specialRewardsLibraryList[i]
                            .indicatorThirdLibraryList
                    );
                }
                for (let n = 0; n < spearr.length; n++) {
                    this.formModel.speValue.push({
                        id: spearr[n].id,
                    });
                }
                // 单项补助及奖惩指标
                for (
                    let i = 0;
                    i < res.data.individualSubsidyLibraryList.length;
                    i++
                ) {
                    punarr.push(
                        ...res.data.individualSubsidyLibraryList[i]
                            .indicatorThirdLibraryList
                    );
                }
                for (let n = 0; n < punarr.length; n++) {
                    this.formModel.punValue.push({
                        id: punarr[n].id,
                    });
                }
                //附加职责
                for (
                    let i = 0;
                    i < res.data.addResponsibilitiesLibraryList.length;
                    i++
                ) {
                    addarr.push(
                        ...res.data.addResponsibilitiesLibraryList[i]
                            .indicatorThirdLibraryList
                    );
                }
                for (let n = 0; n < addarr.length; n++) {
                    this.formModel.addValue.push({
                        id: addarr[n].id,
                    });
                }
                // console.log(this.formModel.addValue);
                // 个人考勤指标
                for (
                    let i = 0;
                    i < res.data.personalAttendanceLibraryList.length;
                    i++
                ) {
                    indarr.push(
                        ...res.data.personalAttendanceLibraryList[i]
                            .indicatorThirdLibraryList
                    );
                }
                for (let n = 0; n < indarr.length; n++) {
                    this.formModel.indValue.push({
                        id: indarr[n].id,
                    });
                }

                let array = [];
                array.push(
                    ...this.formModel.addValue,
                    ...this.formModel.indValue,
                    ...this.formModel.punValue,
                    ...this.formModel.speValue,
                    ...this.formModel.workValue,
                    ...this.formModel.workTwoValue,
                    ...this.formModel.basValue,
                    ...this.formModel.busvValue
                );
                let checArr = [];
                for (let i = 0; i < array.length; i++) {
                    checArr.push(array[i].id);
                }
                this.checkKeys = checArr;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async perupad() {
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let array = [];
                    array.push(
                        ...this.formModel.addValue,
                        ...this.formModel.indValue,
                        ...this.formModel.punValue,
                        ...this.formModel.speValue,
                        ...this.formModel.workValue,
                        ...this.formModel.workTwoValue,
                        ...this.formModel.basValue,
                        ...this.formModel.busvValue
                    );
                    // console.log(array);

                    let data = {
                        departmentId: this.formModel.departmentId, //所属科室id
                        indicatorThirdLibrary: array, //绩效指标库所选指标信息
                    };
                    let { data: res } = await this.$axios.perupad(data);
                    // console.log(res);
                    this.addDepClick = true;
                    this.addDepClickKing = false;
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        this.rewardList();
                        this.AdddialogVisibleClose();
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        async editfindDetail() {
            for (let x = 0; x < this.stepParams.length; x++) {
                if (this.active == this.stepParams[x].index) {
                    // 工作量指标类型（0病例病种赋分   1医疗服务项目  2业务量）  该项参数在指标类型为工作量类型时为必传
                    let data = {
                        departmentId: this.formModel.departmentId,
                        indicatorLibraryType: this.stepParams[x].id,
                        workloadIndicatorType: this.stepParams[x].workType,
                    };
                    let { data: res } = await this.$axios.perfindDetail(data);
                    // console.log(res);
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        this.stepChange();
                        let docArr = [],
                            nurArr = [];
                        for (let i = 0; i < res.data.length; i++) {
                            // console.log(res.data[i].indicatorThirdLibraryList);
                            if (
                                this.stepParams[x].title ==
                                "工作量指标-医疗服务项目"
                            ) {
                                if (res.data[i].name == "医疗服务项目") {
                                    let array = [];
                                    for (
                                        let n = 0;
                                        n <
                                        res.data[i].indicatorThirdLibraryList
                                            .length;
                                        n++
                                    ) {
                                        array.push(
                                            res.data[i]
                                                .indicatorThirdLibraryList[n].id
                                        );
                                        this.checkKeys = array;
                                    }
                                }
                            } else if (
                                this.stepParams[x].title ==
                                "工作量指标-病历病种"
                            ) {
                                if (res.data[i].name == "病历病种") {
                                    let array = [];
                                    for (
                                        let n = 0;
                                        n <
                                        res.data[i].indicatorThirdLibraryList
                                            .length;
                                        n++
                                    ) {
                                        array.push(
                                            res.data[i]
                                                .indicatorThirdLibraryList[n].id
                                        );
                                        this.checkKeys = array;
                                    }
                                }
                            } else if (
                                this.stepParams[x].title == "工作量指标-业务量"
                            ) {
                                if (res.data[i].name == "业务量") {
                                    let array = [];
                                    for (
                                        let n = 0;
                                        n <
                                        res.data[i].indicatorThirdLibraryList
                                            .length;
                                        n++
                                    ) {
                                        array.push(
                                            res.data[i]
                                                .indicatorThirdLibraryList[n].id
                                        );
                                        this.checkKeys = array;
                                        // console.log(array);
                                    }
                                }
                            } else if (this.stepParams[x].title == "附加职责") {
                                if (res.data[i].name == "医生附加职责") {
                                    for (
                                        let n = 0;
                                        n <
                                        res.data[i].indicatorThirdLibraryList
                                            .length;
                                        n++
                                    ) {
                                        docArr.push(
                                            res.data[i]
                                                .indicatorThirdLibraryList[n].id
                                        );
                                        // console.log(array);
                                    }
                                }
                                if (res.data[i].name == "护士附加职责") {
                                    for (
                                        let n = 0;
                                        n <
                                        res.data[i].indicatorThirdLibraryList
                                            .length;
                                        n++
                                    ) {
                                        nurArr.push(
                                            res.data[i]
                                                .indicatorThirdLibraryList[n].id
                                        );

                                        // console.log(array);
                                    }
                                }

                                this.checkKeys = [...docArr, ...nurArr];
                            } else {
                                let array = [];
                                for (
                                    let n = 0;
                                    n <
                                    res.data[i].indicatorThirdLibraryList
                                        .length;
                                    n++
                                ) {
                                    array.push(
                                        res.data[i].indicatorThirdLibraryList[n]
                                            .id
                                    );
                                    this.checkKeys = array;
                                }
                            }
                            let basArr = [],
                                speArr = [],
                                punArr = [],
                                indArr = [],
                                addArr = [],
                                worArr = [],
                                worTwoArr = [],
                                busArr = [];
                            for (
                                let n = 0;
                                n <
                                res.data[i].indicatorThirdLibraryList.length;
                                n++
                            ) {
                                if (this.stepParams[x].title == "基本指标") {
                                    basArr.push({
                                        id: res.data[i]
                                            .indicatorThirdLibraryList[n].id,
                                    });
                                    this.formModel.basValue = basArr;
                                } else if (
                                    this.stepParams[x].title == "专项奖励指标"
                                ) {
                                    speArr.push({
                                        id: res.data[i]
                                            .indicatorThirdLibraryList[n].id,
                                    });
                                    this.formModel.speValue = speArr;
                                } else if (
                                    this.stepParams[x].title ==
                                    "单项补助及奖惩指标"
                                ) {
                                    punArr.push({
                                        id: res.data[i]
                                            .indicatorThirdLibraryList[n].id,
                                    });
                                    this.formModel.punValue = punArr;
                                } else if (
                                    this.stepParams[x].title == "个人考勤指标"
                                ) {
                                    indArr.push({
                                        id: res.data[i]
                                            .indicatorThirdLibraryList[n].id,
                                    });
                                    this.formModel.indValue = indArr;
                                } else if (
                                    this.stepParams[x].title == "附加职责"
                                ) {
                                    addArr.push({
                                        id: res.data[i]
                                            .indicatorThirdLibraryList[n].id,
                                    });
                                    this.formModel.addValue = addArr;
                                } else if (
                                    this.stepParams[x].title ==
                                    "工作量指标-医疗服务项目"
                                ) {
                                    if (res.data[i].name == "医疗服务项目") {
                                        worArr.push({
                                            id: res.data[i]
                                                .indicatorThirdLibraryList[n]
                                                .id,
                                        });
                                        this.formModel.workValue = worArr;
                                    }
                                } else if (
                                    this.stepParams[x].title ==
                                    "工作量指标-病历病种"
                                ) {
                                    if (res.data[i].name == "病历病种") {
                                        worTwoArr.push({
                                            id: res.data[i]
                                                .indicatorThirdLibraryList[n]
                                                .id,
                                        });
                                        this.formModel.workTwoValue = worTwoArr;
                                    }
                                } else if (
                                    this.stepParams[x].title ==
                                    "工作量指标-业务量"
                                ) {
                                    if (res.data[i].name == "业务量") {
                                        busArr.push({
                                            id: res.data[i]
                                                .indicatorThirdLibraryList[n]
                                                .id,
                                        });
                                        this.formModel.busvValue = busArr;
                                    }
                                }
                            }
                        }

                        // console.log(this.checkKeys);
                    }
                }
            }
        },
        showdepp(val) {
            // console.log(val);
            this.AdddialogVisible = true;
            this.addVal = false;
            this.stepShow = true;
            this.active = 0;
            this.disabled = true;
            this.checShow = false;
            this.stepChange();
            this.formModel.departmentId = val.departmentId;
            this.perfindDetail();
        },
        async perfindDetail() {
            let data = {
                departmentId: this.formModel.departmentId,
                indicatorLibraryType: "",
            };
            let { data: res } = await this.$axios.perfindDetail(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                let array = [];
                if (res.data.indicatorLibraryList) {
                    for (
                        let i = 0;
                        i < res.data.indicatorLibraryList.length;
                        i++
                    ) {
                        array.push(res.data.indicatorLibraryList[i].id);
                    }
                }
                this.checkKeys = array;
                // console.log(array);
            }
        },
        async addDepp() {
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let array = [];
                    array.push(
                        ...this.formModel.addValue,
                        ...this.formModel.indValue,
                        ...this.formModel.punValue,
                        ...this.formModel.speValue,
                        ...this.formModel.workValue,
                        ...this.formModel.workTwoValue,
                        ...this.formModel.basValue,
                        ...this.formModel.busvValue
                    );

                    let data = {
                        departmentId: this.formModel.departmentId, //所属科室id
                        indicatorThirdLibrary: array, //绩效指标库所选指标信息
                    };
                    let { data: res } = await this.$axios.opeadd(data);
                    // console.log(res);
                    this.addDepClick = true;
                    this.addDepClickKing = false;
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        this.rewardList();
                        this.AdddialogVisibleClose();
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.rewardList();
        },
        // 点击新增按钮
        depaddKeshi() {
            this.keshidialogVisible = true;
            this.stepChange();
            this.updatShow = false;
        },
        keshiBtn() {
            this.$refs.formDepRef.validate(async (valid) => {
                if (valid) {
                    this.AdddialogVisible = true;
                }
            });
        },
        keshidialogVisibleClose() {
            this.keshidialogVisible = false;
            this.formModelDep = {
                departmentId: "",
            };
            this.$refs.formDepRef.resetFields();
        },
        async findDepartment() {
            let data = {
                hospitalId: window.sessionStorage.getItem("hospitalId"), //登录后返回的 医院id
                nameLike: "", //查询用的 科室名称
                orderByColumn: "createDate", //排序参数 updateDate 更新时间
                isAsc: "desc", //asc 升序 desc降序
            };
            let { data: res } = await this.$axios.findDepartment(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.depoptions = res.data;
            }
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.checShow = true;
            this.updatShow = false;
            this.AdddialogVisible = false;
            this.keshidialogVisible = false;
            this.editShow = false;
            this.nextDepClick = true;
            this.nextDepClickKing = false;
            this.formModelDep = {
                departmentId: "",
            };
            this.formModel = {
                departmentId: "",
                target: [],
                basValue: [], //基本指标
                workValue: [],
                workTwoValue: [],
                speValue: [],
                punValue: [],
                indValue: [],
                addValue: [],
                busvValue: [],
            };
            this.checkKeys = [];
            this.basdata = [];
            this.docadddata = [];
            this.nuradddata = [];
            this.diaTit = "配置指标";
            this.diaBtn = "新增";
            this.addVal = true;
            this.$refs.formRef.resetFields();
            this.baShow = false;
            this.workShow = false;
            this.speShow = false;
            this.punShow = false;
            this.indShow = false;
            this.addShow = false;
            this.stepBtn = "下一步";
            this.stepShow = true;
            this.disabled = false;
            this.active = 0;

            this.baschecked = false;
            this.buschecked = false;
            this.spechecked = false;

            this.indechecked = false;
            this.puninchecked = false;
            this.addreschecked = false;
        },
        // 分页显示病区列表数据
        async rewardList() {
            this.AdddialogVisible = false;
            this.diaBtn = "新增";
            this.diaTit = "配置指标";
            let data = {
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                indicatorLibraryType: "", //指标类型（0基础指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                deleteFlag: "0", //数据状态（0正常 1已删除）
                departmentId: this.searcdepartmentId, //所属科室id
                groupByDepartment: "true", //列表数据展示请传此参数
            };
            let { data: res } = await this.$axios.openlist(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .addClass .el-form-item__content {
    width: 90% !important;
}

.stepClass {
    text-align: center;

    .el-tree {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: 0 auto;
    }

    .el-checkbox {
        margin-top: 20px;
    }

    //     margin-top: 30px;
    //     width: 100%;
    //     text-align: center;
    //     .el-checkbox:nth-child(1) {
    //         margin-bottom: 8px;
    //     }
    //     .el-form-item {
    //         display: flex;
    //         justify-content: center;
    //         /deep/ .el-form-item__content {
    //             margin-left: 0px !important;
    //             width: 90%;
    //         }
    //     }
}

.btnCen {
    text-align: center;
    margin-left: 5px;

    button {
        width: 150px;
    }
}

.btnBig {
    display: flex;
    justify-content: center;

    div {
        margin-right: 20px;
    }
}

.RightDiv {
    margin: 0 auto;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}

.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

.depar-dia {
    width: 100%;
}

.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}

/deep/ .el-radio-button__inner {
    width: 100px;
}

.el-pagination {
    margin-top: 20px;
}

/deep/ .el-dialog__body {
    text-align: inherit;
}

.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;

        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.hosrow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;

    div {
        margin-left: 10px;
    }
}

.activeStatus {
    background: rgb(102, 204, 0);
}

.noStatus {
    background: #ccc;
}

.treeTwo {
    width: 100%;
    display: flex;

    // justify-content: space-around;
    div {
        flex: 1;
    }
}

.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

/deep/ .el-input .el-input__inner {
    width: 300px;
}

/deep/ .hosrow .el-input .el-input__inner {
    width: 200px;
}

/deep/ .el-step__title.is-success {
    color: #409eff;
}

/deep/ .el-step__head.is-success {
    color: #409eff;
    border-color: #409eff;
}

/deep/ .el-step__title {
    font-size: 15px !important;
}

.heiClas {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
}

/deep/ .formClaRul .el-form-item__error {
    top: 0px !important;
}

/deep/.formClaRul .tabClas .el-form-item__error {
    left: 5% !important;
}

.centerClas {
    width: 100%;
    display: flex;
    // justify-content: center;
}

/deep/ .tabClas .el-form-item__content {
    margin-left: 0px !important;
}

/deep/ .keshiClas .el-input .el-input__inner {
    width: 200px;
}
</style>
